import React, { Dispatch, SetStateAction, useState } from 'react';
import { FirestoreCollection } from '@react-firebase/firestore';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { db } from '../../firebase/firebaseConfigs';

import Social from '../Social/Social';
import Modal from '../common/Modal/Modal';

import { ReactComponent as Close } from '../Icons/close.svg';
import { ReactComponent as FileIcon } from '../Icons/file.svg';

import { ReactComponent as InfoBackgr } from '../Icons/infoBackgr.svg';
import { ReactComponent as InfoIcon } from '../Icons/infoIcon.svg';

import './ContactUsModal.scss';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import ReactDOMServer from 'react-dom/server';

function convertToString(data: string | ArrayBuffer | null): string {
	if (data === null) {
		return 'null'; // Handle null case separately
	} else if (typeof data === 'string') {
		return data; // If it's already a string, return as is
	} else if (data instanceof ArrayBuffer) {
		// Convert ArrayBuffer to a string
		const uintArray = new Uint8Array(data);
		const charArray = uintArray.map((byte) => +String.fromCharCode(byte));
		return charArray.join('');
	} else {
		// Handle other data types, if needed
		return String(data);
	}
}
interface ContactUsModalProps {
	contactModal: boolean;
	setContactModal: Dispatch<SetStateAction<boolean>>;
	extraStaff?: boolean;
	reward?: boolean;
}

interface FormData {
	name: string;
	lastName: string;
	email: string;
	yourEmail: string;
	candidatName: string;
	candidatInterests: string;
	candidatEmail: string;
	phone: string;
	data: string;
	text: string;
	file: string | ArrayBuffer | null;
}

export default function ContactUsModal({ contactModal, setContactModal, extraStaff, reward }: ContactUsModalProps) {
	const { register, handleSubmit, errors } = useForm();
	const [formData, setFormData] = useState<FormData>({
		name: '',
		lastName: '',
		email: '',
		yourEmail: '',
		candidatName: '',
		candidatInterests: '',
		candidatEmail: '',
		phone: '',
		data: '',
		text: '',
		file: null,
	});
	const [fileName, setFileName] = useState('');
	const [emailTemplate, setEmailTemplate] = useState<string>('');
	const [replyEmail, setReplyEmail] = useState('');
	const [sendStatus, setSendStatus] = useState<number | undefined>();
	const [fileLoadStatus, setFileLoadStatus] = useState<number | undefined>();
	const [disableSendButton, setDisableSendButton] = useState(false);
	const [language] = useSessionStorage('language', '');
	const [emailFile, setEmailFile] = useState<string | ArrayBuffer | null>(null);
	const [fileFormat, setFileFormat] = useState<string>('');

	const getBase64 = (file: any) => {
		setFileFormat(file.name.slice((Math.max(0, file.name.lastIndexOf('.')) || Infinity) + 1));
		const maxFileSize = 10000000;
		if (file.size < maxFileSize) {
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				setFormData({ ...formData, file: reader.result });
				setEmailFile(reader.result);
			};
			reader.onerror = (error) => {
				console.log('Error: ', error);
			};
			setFileLoadStatus(200);
		} else {
			setFileLoadStatus(400);
			setFormData({
				...formData,
				file: null,
			});
			setFileName('');
		}
	};

	const updateInput = (e: any) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
		setSendStatus(undefined);
	};
	const onSubmit = () => {
		sendEmail();
		if (reward) {
			sendReferral();
		}
		setSendStatus(undefined);
	};
	const sendEmail = () => {
		setDisableSendButton(true);
		db.collection('completedForms')
			.add({
				name: formData.name,
				lastName: formData.lastName,
				email: formData.email,
				phone: formData.phone,
				text: formData.text,
				dataOnCall: formData.data,
				developer: false,
				reward: reward ? true : false,
				extraStaff: extraStaff ? true : false,
				getInTouch: !extraStaff && !reward ? true : false,
				createDate: new Date(),
			})
			.then(() => setSendStatus(200))
			.catch(() => setSendStatus(400));

		db.collection('buildEmail').add({
			to: [replyEmail],
			message: {
				subject: `${
					reward ? 'New candidate record has been added by website' : 'New contact record has been added by website'
				} - Global IT Support`,
				html: emailTemplate,
				attachments: emailFile
					? [
							{
								filename: fileName,
								path: emailFile,
							},
					  ]
					: null,
			},
		});
		setFormData({
			name: '',
			lastName: '',
			candidatEmail: '',
			candidatInterests: '',
			candidatName: '',
			yourEmail: '',
			email: '',
			phone: '',
			data: '',
			text: '',
			file: null,
		});
		setFileName('');
		setDisableSendButton(false);
	};

	const sendReferral = () => {
		var formdata = new FormData();
		formdata.append('referred_by', formData.name);
		formdata.append('referred_email', formData.yourEmail);
		formdata.append('candidate_name', formData.candidatName);
		formdata.append('candidate_email', formData.email);
		formdata.append('candidate_phone', formData.phone);
		formdata.append('interests', formData.candidatInterests);
		formdata.append('cv_file', convertToString(formData.file));
		formdata.append('file_format', fileFormat);

		var requestOptions = {
			method: 'POST',
			body: formdata,
			// redirect: 'follow',
		};

		fetch('https://my.gisua.com/create-applicant?api_key=eea0b382-29c1-4kolia-9077-fb9396c2a344', requestOptions)
			.then((response) => response.text())
			.then((result) => console.log(result))
			.catch((error) => console.log('error', error));
	};

	return (
		<Modal isOpen={contactModal} onRequestClose={() => setContactModal(false)} className="contactUsModalOverlay">
			<FirestoreCollection path="/modalForms/">
				{(data) => {
					if (data.isLoading) {
						return;
					}

					const dataF = extraStaff ? data.value[0].extraStaff : reward ? data.value[0].reward : data.value[0].contactUs;

					setReplyEmail(dataF.replyEmail);

					const template = ReactDOMServer.renderToString(
						<html>
							<head>
								<meta charSet="utf-8" />
							</head>
							<body>
								<div
									style={{
										width: '600px',
										margin: '40px auto 0',
										padding: '0 20px',
									}}
								>
									<img
										alt=""
										style={{ width: '100%' }}
										src="https://firebasestorage.googleapis.com/v0/b/admin-gisua.appspot.com/o/headRes.png?alt=media&token=a0d7cf32-7856-468f-9784-4adfa65247eb"
									/>
									<div
										style={{
											textAlign: 'center',
											padding: '15px 20px 0',
											fontSize: '16px',
											fontWeight: 'bold',
											maxWidth: '750px',
											margin: '0 auto',
											textTransform: 'uppercase',
											color: '#24292e',
										}}
									>
										{dataF.title}
									</div>
									<div style={{ width: '100%' }}>
										<div
											style={{
												maxWidth: '1150px',
												margin: '0 auto',
												padding: '20px 0',
											}}
										>
											<div
												style={{
													color: '#24292e',
													fontWeight: 'bold',
													padding: '17px 0',
													minWidth: '200px',
													fontSize: '14px',
													whiteSpace: 'nowrap',
													display: 'inline-block',
												}}
											>
												Name:
											</div>
											<div style={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block', color: '#24292e' }}>
												{formData.name}
											</div>
										</div>
										{formData.lastName && (
											<div
												style={{
													maxWidth: '1150px',
													margin: '0 auto',
													padding: '20px 0',
												}}
											>
												<div
													style={{
														color: '#24292e',
														fontWeight: 'bold',
														padding: '17px 0',
														minWidth: '200px',
														fontSize: '14px',
														whiteSpace: 'nowrap',
														display: 'inline-block',
													}}
												>
													Last Name:
												</div>
												<div style={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block', color: '#24292e' }}>
													{formData.lastName}
												</div>
											</div>
										)}
										{formData.email && (
											<div
												style={{
													maxWidth: '1150px',
													margin: '0 auto',
													padding: '20px 0',
												}}
											>
												<div
													style={{
														color: '#24292e',
														fontWeight: 'bold',
														padding: '17px 0',
														minWidth: '200px',
														fontSize: '14px',
														whiteSpace: 'nowrap',
														display: 'inline-block',
													}}
												>
													Email:
												</div>
												<div style={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block', color: '#24292e' }}>
													{formData.email}
												</div>
											</div>
										)}
										{formData.yourEmail && (
											<div
												style={{
													maxWidth: '1150px',
													margin: '0 auto',
													padding: '20px 0',
												}}
											>
												<div
													style={{
														color: '#24292e',
														fontWeight: 'bold',
														padding: '17px 0',
														minWidth: '200px',
														fontSize: '14px',
														whiteSpace: 'nowrap',
														display: 'inline-block',
													}}
												>
													Your Email:
												</div>
												<div style={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block', color: '#24292e' }}>
													{formData.yourEmail}
												</div>
											</div>
										)}
										{formData.candidatName && (
											<div
												style={{
													maxWidth: '1150px',
													margin: '0 auto',
													padding: '20px 0',
												}}
											>
												<div
													style={{
														color: '#24292e',
														fontWeight: 'bold',
														padding: '17px 0',
														minWidth: '200px',
														fontSize: '14px',
														whiteSpace: 'nowrap',
														display: 'inline-block',
													}}
												>
													Candidate Name:
												</div>
												<div style={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block', color: '#24292e' }}>
													{formData.candidatName}
												</div>
											</div>
										)}
										{formData.candidatInterests && (
											<div
												style={{
													maxWidth: '1150px',
													margin: '0 auto',
													padding: '20px 0',
												}}
											>
												<div
													style={{
														color: '#24292e',
														fontWeight: 'bold',
														padding: '17px 0',
														minWidth: '200px',
														fontSize: '14px',
														whiteSpace: 'nowrap',
														display: 'inline-block',
													}}
												>
													Candidate Interests:
												</div>
												<div style={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block', color: '#24292e' }}>
													{formData.candidatInterests}
												</div>
											</div>
										)}
										{formData.candidatEmail && (
											<div
												style={{
													maxWidth: '1150px',
													margin: '0 auto',
													padding: '20px 0',
												}}
											>
												<div
													style={{
														color: '#24292e',
														fontWeight: 'bold',
														padding: '17px 0',
														minWidth: '200px',
														fontSize: '14px',
														whiteSpace: 'nowrap',
														display: 'inline-block',
													}}
												>
													Candidate Email:
												</div>
												<div style={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block', color: '#24292e' }}>
													{formData.candidatEmail}
												</div>
											</div>
										)}
										{formData.phone && (
											<div
												style={{
													maxWidth: '1150px',
													margin: '0 auto',
													padding: '20px 0',
												}}
											>
												<div
													style={{
														color: '#24292e',
														fontWeight: 'bold',
														padding: '17px 0',
														minWidth: '200px',
														fontSize: '14px',
														whiteSpace: 'nowrap',
														display: 'inline-block',
													}}
												>
													Phone:
												</div>
												<div style={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block', color: '#24292e' }}>
													{formData.phone}
												</div>
											</div>
										)}
										{formData.text && (
											<div
												style={{
													maxWidth: '1150px',
													margin: '0 auto',
													padding: '20px 0',
												}}
											>
												<div
													style={{
														color: '#24292e',
														fontWeight: 'bold',
														padding: '17px 0',
														minWidth: '200px',
														fontSize: '14px',
														whiteSpace: 'nowrap',
														display: 'inline-block',
													}}
												>
													Message:
												</div>
												<div style={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block', color: '#24292e' }}>
													{formData.text}
												</div>
											</div>
										)}
									</div>
								</div>
							</body>
						</html>
					);

					return (
						<>
							<div className="contactUsModal">
								<div className="contactUsTitle">{language === 'ua' ? dataF.titleUa : dataF.title}</div>
								{dataF.subTitle && (
									<div className="contactUsSubTitle">
										{language === 'ua' && dataF.subTitleUa ? dataF.subTitleUa : dataF.subTitle}
									</div>
								)}
								<form onSubmit={handleSubmit(onSubmit)}>
									<input
										name="name"
										type="text"
										className={errors.name ? 'contactUsInputError' : 'contactUsInput'}
										placeholder={language === 'ua' ? "Ваше повне ім'я" : 'Your full name'}
										ref={register({
											required: 'Required Name',
											minLength: 2,
										})}
										onChange={updateInput}
										value={formData.name || ''}
									/>
									{reward && (
										<input
											name="yourEmail"
											type="text"
											className={errors.email ? 'contactUsInputError' : 'contactUsInput'}
											placeholder={language === 'ua' ? 'Ваша електронна адреса' : 'Your email address'}
											ref={register({
												required: 'Required Email',
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
													message: 'invalid email address',
												},
											})}
											onChange={updateInput}
											value={formData.yourEmail || ''}
										/>
									)}
									{reward && (
										<input
											name="candidatName"
											type="text"
											className={errors.name ? 'contactUsInputError' : 'contactUsInput'}
											placeholder={language === 'ua' ? "Повне ім'я кандидата" : 'Candidate full name'}
											ref={register({
												required: 'Required Name',
												minLength: 2,
											})}
											onChange={updateInput}
											value={formData.candidatName || ''}
										/>
									)}
									{reward && (
										<input
											name="candidatInterests"
											type="text"
											className={errors.name ? 'contactUsInputError' : 'contactUsInput'}
											placeholder={language === 'ua' ? 'Професійні інтереси кандидата' : 'Candidate professional interests '}
											ref={register({
												required: 'Required Name',
												minLength: 2,
											})}
											onChange={updateInput}
											value={formData.candidatInterests || ''}
										/>
									)}
									{reward && (
										<input
											name="candidatEmail"
											type="text"
											className={errors.email ? 'contactUsInputError' : 'contactUsInput'}
											placeholder={language === 'ua' ? 'Електронна адреса кандидата' : 'Candidate email address'}
											ref={register({
												required: 'Required Email',
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
													message: 'invalid email address',
												},
											})}
											onChange={updateInput}
											value={formData.candidatEmail || ''}
										/>
									)}
									{extraStaff && (
										<input
											type="text"
											name="lastName"
											className="contactUsInput"
											placeholder={language === 'ua' ? 'Прізвище' : 'Last name'}
											onChange={updateInput}
											value={formData.lastName || ''}
										/>
									)}
									{!reward && (
										<input
											name="email"
											type="text"
											className={errors.email ? 'contactUsInputError' : 'contactUsInput'}
											placeholder={language === 'ua' ? 'Електронна пошта' : 'Email address'}
											ref={register({
												required: 'Required Email',
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
													message: 'invalid email address',
												},
											})}
											onChange={updateInput}
											value={formData.email || ''}
										/>
									)}
									{(extraStaff || reward) && (
										<input
											type="text"
											name="phone"
											className={errors.phone ? 'contactUsInputError' : 'contactUsInput'}
											placeholder={
												language === 'ua'
													? `${reward ? 'Номер кандидата' : 'Телефонний номер'}`
													: `${reward ? 'Candidate phone ' : 'Phone number'}`
											}
											ref={register({
												required: 'Required Phone',
												pattern: {
													value: /^\+?[0-9- ]{9,20}$/i,
													message: 'invalid phone number',
												},
											})}
											onChange={updateInput}
											value={formData.phone || ''}
										/>
									)}
									{reward && (
										<div className="contactUsInputFileWrapper">
											<FileIcon />
											<input
												type="file"
												name="file"
												id="file"
												className="contactUsInputFile"
												onChange={(e) => {
													setFileName(e.target.value);
													getBase64(e.target.files && e.target.files[0]);
												}}
												value={fileName}
											/>
											<label htmlFor="file" className="contactInputFile">
												{fileName !== ''
													? fileName.replace(/.*[/\\]/, '')
													: language === 'ua'
													? 'Прикріпити резюме'
													: 'Attach CV'}
											</label>
										</div>
									)}
									{(!extraStaff || !reward) && (
										<textarea
											name="text"
											className="contactUsTextArea"
											rows={4}
											placeholder={language === 'ua' ? 'Повідомлення...' : 'Message...'}
											onChange={updateInput}
											value={formData.text || ''}
										/>
									)}
									<div className="contactUsEmailStatus">
										{sendStatus === 200 && (
											<div className="contactUsSuccess">{language === 'ua' ? 'Успішно' : 'Successfully'}</div>
										)}
										{fileLoadStatus === 400 && (
											<div className="plannerError">
												{language === 'ua' ? 'Розмір файлу має бути менше 10 Мб' : 'File size must be less than 10mb'}
											</div>
										)}
										{sendStatus === 400 && (
											<div className="contactUsError">{language === 'ua' ? 'Щось пішло не так' : 'Something went wrong'}</div>
										)}
										{errors.email && <div className="contactUsError">{errors.email.message}</div>}
									</div>
									<button
										type="submit"
										onClick={() => setEmailTemplate(template)}
										disabled={disableSendButton}
										className="contactUsButton"
									>
										{language === 'ua' ? dataF.buttonTextUa : dataF.buttonText}
									</button>
								</form>
								<button onClick={() => setContactModal(false)} className="closeContactUsModal">
									{language === 'ua' ? 'закрити' : 'close'} <Close />
								</button>
							</div>
							<div className="contactUsModalFooter">
								<div className="contactUsModalFooterContact">
									<InfoBackgr className="infoBackgr" />
									<InfoIcon /> <Link to="/contact">{language === 'ua' ? 'Контактна інформація' : 'Contact information'}</Link>
								</div>
								<div className="contactSocialIconsWrapper">
									<Social />
								</div>
							</div>
						</>
					);
				}}
			</FirestoreCollection>
		</Modal>
	);
}
