import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

// Initialize Firebase
export const config = {
    apiKey: "AIzaSyCT6pVVdoDtNqjINk6hnqLue2LTw1b-PIM",
    authDomain: "admin-gisua.firebaseapp.com",
    databaseURL: "https://admin-gisua-default-rtdb.firebaseio.com",
    projectId: "admin-gisua",
    storageBucket: "admin-gisua.appspot.com",
    messagingSenderId: "712453955227",
}

firebase.initializeApp(config)

const db = firebase.firestore()

export { db }