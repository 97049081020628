import React, { Dispatch, SetStateAction } from 'react';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { ImageResizer } from '../../../Types/ImageResizer';

import './Covid.scss';

interface CovidProps {
	setCovidOpened: Dispatch<SetStateAction<boolean>>;
	title: string;
	titleUa: string;
	text: string;
	textUa: string;
	buttonText: string;
	buttonTextUa: string;
	openedImage: ImageResizer;
}

export default function Covid({
	setCovidOpened,
	title,
	titleUa,
	text,
	textUa,
	buttonText,
	buttonTextUa,
	openedImage,
}: CovidProps) {
	const [language] = useSessionStorage('language', '');
	return (
		<div className="covidWrapper">
			<div className="covidContent">
				<img src={openedImage.original.src} alt="" className="openedInfoImage" />
				<div className="covidTitle">{language === 'ua' ? titleUa : title}</div>
				<span dangerouslySetInnerHTML={{ __html: `${language === 'ua' ? textUa : text}` }}></span>
				<div>
					<button onClick={() => setCovidOpened(false)} className="covidButton">
						{language === 'ua' ? buttonTextUa : buttonText}
					</button>
				</div>
			</div>
		</div>
	);
}
