import React, { Dispatch, SetStateAction, useState } from 'react';
import { FirestoreCollection } from '@react-firebase/firestore';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import Social from '../../Social/Social';
import Covid from '../../common/Covid/Covid';

import Modal from '../../common/Modal/Modal';

import { ReactComponent as Vector } from '../../Icons/vector.svg';
import { ReactComponent as CloseBtn } from '../../Icons/closeBtn.svg';
import { ReactComponent as Right } from '../../Icons/right.svg';
import { ReactComponent as Shape } from '../../Icons/shape.svg';

import './MenuModal.scss';

interface MenuProps {
	menuOpened: boolean;
	covidOpened: boolean;
	setMenuOpened: Dispatch<SetStateAction<boolean>>;
	setSitemapOpened: Dispatch<SetStateAction<boolean>>;
	setCovidOpened: Dispatch<SetStateAction<boolean>>;
	language: string;
	setLanguage: Dispatch<SetStateAction<string>>;
	langDropdownOpened: boolean;
	setLangDropdownOpened: Dispatch<SetStateAction<boolean>>;
}

export default function Menu({
	menuOpened,
	setMenuOpened,
	setCovidOpened,
	covidOpened,
	language,
	setLanguage,
	langDropdownOpened,
	setLangDropdownOpened,
}: MenuProps) {
	const [shownExpertise, setShownExpertise] = useState(false);
	const [shownAbout, setShownAbout] = useState(false);
	return (
		<Modal isOpen={menuOpened} onRequestClose={() => setMenuOpened(false)} className="menuModalOverlay">
			<div className="menuModalContent">
				<div
					onClick={() => {
						setMenuOpened(false);
						setShownAbout(false);
						setShownExpertise(false);
					}}
					className="menuCloseWrapper"
				>
					<CloseBtn />
				</div>
				<div className="menuLinksWrapper">
					<div className="menuTitle">Menu</div>
					<div className="menuLinks">
						<div className="menuLinksContainer">
							<div className="menuLink">
								<Link
									onClick={() => {
										setMenuOpened(false);
									}}
									to="/about"
									className="menuLink"
								>
									{language === 'ua' ? 'Про нас' : 'About'}{' '}
								</Link>
								{language !== 'ua' && (
									<Right
										onClick={() => {
											setShownAbout(!shownAbout);
										}}
										className={shownAbout ? 'openMenu' : 'closeMenu'}
									/>
								)}
							</div>

							{shownAbout && language !== 'ua' && (
								<div className="menuLinkOpened">
									<HashLink
										to="/about#company"
										onClick={() => {
											setMenuOpened(false);
											setShownAbout(false);
										}}
										className="menuLink"
									>
										Company
									</HashLink>
									<Link
										to="/team"
										onClick={() => {
											setMenuOpened(false);
											setShownAbout(false);
										}}
										className="menuLink"
									>
										The team
									</Link>
									<Link
										onClick={() => {
											setMenuOpened(false);
											setShownAbout(false);
										}}
										to="/techTalent"
										className="menuLink"
									>
										Tech Talent
									</Link>
								</div>
							)}
							<div className="menuLink">
								<Link
									onClick={() => {
										setMenuOpened(false);
									}}
									to="/services"
									className="menuLink"
								>
									{language === 'ua' ? 'Наші послуги' : 'Services'}{' '}
								</Link>
								<Right
									onClick={() => {
										setShownExpertise(!shownExpertise);
									}}
									className={shownExpertise ? 'openMenu' : 'closeMenu'}
								/>
							</div>
							{shownExpertise && (
								<div className="menuLinkOpened">
									<Link
										to="/service/recruitment"
										onClick={() => {
											setMenuOpened(false);
											setShownExpertise(false);
										}}
										className="menuLink"
									>
										{language === 'ua' ? 'Рекрутинг' : 'Recruitment'}
									</Link>
									<Link
										to="/service/project-coordination"
										onClick={() => {
											setMenuOpened(false);
											setShownExpertise(false);
										}}
										className="menuLink"
									>
										{language === 'ua' ? 'Координація проектів' : 'Project Coordination'}
									</Link>
									<Link
										to="/service/support"
										onClick={() => {
											setMenuOpened(false);
											setShownExpertise(false);
										}}
										className="menuLink"
									>
										{language === 'ua' ? 'Підтримка' : 'Support'}
									</Link>
									<Link
										to="/service/human-resources"
										onClick={() => {
											setMenuOpened(false);
											setShownExpertise(false);
										}}
										className="menuLink"
									>
										{language === 'ua' ? 'Human Resources' : 'Human Resources'}
									</Link>
									<Link
										to="/service/sales"
										onClick={() => {
											setMenuOpened(false);
											setShownExpertise(false);
										}}
										className="menuLink"
									>
										{language === 'ua' ? 'Маркетинг та продажі' : 'Sales and Marketing'}
									</Link>
									<Link
										to="/service/ict-management"
										onClick={() => {
											setMenuOpened(false);
											setShownExpertise(false);
										}}
										className="menuLink"
									>
										{language === 'ua' ? 'Управління інфраструктурою' : 'ICT Management'}
									</Link>
								</div>
							)}
							<Link
								onClick={() => {
									setMenuOpened(false);
									setShownAbout(false);
									setShownExpertise(false);
								}}
								to="/contact"
								className="menuLink"
							>
								<HashLink to="/contact#contactPlanner" className="menuLink">
									{language === 'ua' ? 'Контакти' : 'Contact'}
								</HashLink>
							</Link>
							{language !== 'ua' && (
								<>
									<Link
										onClick={() => {
											setMenuOpened(false);
											setShownAbout(false);
											setShownExpertise(false);
										}}
										to="/myteam"
										className="menuLink"
									>
										Build a Team
									</Link>
									{/* <Link
										onClick={() => {
											setMenuOpened(false);
											setShownAbout(false);
											setShownExpertise(false);
										}}
										to="/blog"
										className="menuLink"
									>
										Blog
									</Link> */}
								</>
							)}
							<Link
								onClick={() => {
									setMenuOpened(false);
									setShownAbout(false);
									setShownExpertise(false);
								}}
								to="/vacancies"
								className="menuLink"
							>
								{language === 'ua' ? 'Відкриті вакансії' : 'Job Opening'}
							</Link>
							{language !== 'ua' && (
								<Link
									onClick={() => {
										setMenuOpened(false);
										setShownExpertise(false);
									}}
									to="/joinus"
									className="menuLink"
								>
									<HashLink to="/joinus#joinUsPlanner" className="menuLink">
										Join Us
									</HashLink>
								</Link>
							)}
						</div>
					</div>
				</div>
				<div className="menuBackgraundWrapper">
					<Vector className="menuModalVector" />
				</div>
				<div className="menuModalSocialWrapper">
					<div className="languageContainerMob">
						<div
							className={langDropdownOpened ? 'languageSelectorOpened' : 'languageSelector'}
							onClick={() => setLangDropdownOpened(!langDropdownOpened)}
						>
							{language !== 'ua' ? 'EN' : 'UA'}
							<Shape />
						</div>
						{langDropdownOpened && (
							<div className="languageDropdown">
								<div
									className="languageDropdownValue"
									onClick={() => {
										setLanguage(language === 'en' ? 'ua' : 'en');
										window.location.reload();
									}}
								>
									{language === 'en' ? 'UA' : 'EN'}
								</div>
							</div>
						)}
					</div>
					<div>
						<Social />
					</div>
					<FirestoreCollection path="/infoModal/">
						{(infoData) => {
							if (infoData.isLoading) {
								return;
							}
							return infoData.value[0].shown && infoData.value[0].image.sm.src ? (
								<div className="menuModalCovidWrapper">
									<div onClick={() => setCovidOpened(true)} className="menuModalCovidButton">
										<img src={infoData.value[0].image.sm.src} alt="" />
									</div>
									{covidOpened && <Covid setCovidOpened={setCovidOpened} {...infoData.value[0]} />}
								</div>
							) : null;
						}}
					</FirestoreCollection>
				</div>
			</div>
		</Modal>
	);
}
