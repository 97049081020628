import React, { Dispatch, SetStateAction, useState } from 'react';
import { FirestoreCollection } from '@react-firebase/firestore';

import { ReactComponent as Right } from '../../Icons/right.svg';

import './Cookies.scss';

interface CookiesProps {
	setCookiesAccepted: Dispatch<SetStateAction<string>>;
	setCookiesClosed: Dispatch<SetStateAction<boolean>>;
	language: string;
}

export default function Cookies({ setCookiesAccepted, setCookiesClosed, language }: CookiesProps) {
	const [collapseCookies, setCollapseCookies] = useState(true);

	return (
		<FirestoreCollection path="/cookies/">
			{(data) => {
				if (data.isLoading) {
					return;
				}
				return (
					<div className="cookiesWrapper">
						<div className="cookiesContent">
							<div className="cookiesContentWrapper">
								<span
									dangerouslySetInnerHTML={{
										__html: `${
											collapseCookies
												? language === 'ua'
													? data.value[0].shortTextUa
													: data.value[0].shortText
												: language === 'ua'
												? data.value[0].textUa
												: data.value[0].text
										}`,
									}}
								></span>
								<div
									className={collapseCookies ? 'expandCookies' : 'collapsCookies'}
									onClick={() => setCollapseCookies(!collapseCookies)}
								>
									{collapseCookies
										? language === 'ua'
											? 'Детальніше'
											: 'See details'
										: language === 'ua'
										? 'Сховати'
										: 'Hide details'}{' '}
									<Right />
								</div>
							</div>
							<div className="cookiesButtonWrapper">
								<button
									onClick={() => {
										setCookiesAccepted('');
										setCookiesClosed(true);
									}}
									className="cookiesButton"
								>
									{language === 'ua' ? data.value[0].closeButtonTextUa : data.value[0].closeButtonText}
								</button>
								<button onClick={() => setCookiesAccepted('accepted')} className="cookiesReadMore">
									{language === 'ua' ? data.value[0].submitButtonTextUa : data.value[0].submitButtonText}
								</button>
							</div>
						</div>
					</div>
				);
			}}
		</FirestoreCollection>
	);
}
