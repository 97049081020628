import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { FirestoreCollection } from '@react-firebase/firestore';
import { HashLink } from 'react-router-hash-link';

import Sitemap from './Sitemap/Sitemap';
import Social from '../Social/Social';

import './Footer.scss';

interface FooterProps {
	sitemapOpened: boolean;
	setSitemapOpened: Dispatch<SetStateAction<boolean>>;
	language: string;
}

export default function Footer({ sitemapOpened, setSitemapOpened, language }: FooterProps) {
	return (
		<div className="footer">
			<FirestoreCollection path="/footer/">
				{(data) => {
					return (
						!data.isLoading && (
							<div className="сertificatesWrapper">
								<img className="certificateImage" src={data.value[0].footer.image.lg.src} alt=""></img>
							</div>
						)
					);
				}}
			</FirestoreCollection>
			<div className="footerLinks">
				<div onClick={() => setSitemapOpened(true)} className="footerLink">
					{language === 'ua' ? 'Карта сайту' : 'Sitemap'}
				</div>
				<Link to="/terms" className="footerLink">
					{language === 'ua' ? 'Правила та умови' : 'Terms'}
				</Link>
				<Link to="/privacy" className="footerLink">
					{language === 'ua' ? 'Політика Конфіденційності' : 'Privacy'}
				</Link>
				<HashLink to="/contact#contactPlanner" className="footerLink">
					{language === 'ua' ? 'Контакти' : 'Contact'}
				</HashLink>
			</div>
			<div className="footerSocialIconsWrapper">
				<Social />
			</div>
			<div className="footerEndCopyrightWrapper">
				<div className="footerEndCopyright">
					<div className="footerCopyright">
						Copyright @ 2023 GIS. <span>{language === 'ua' ? 'Всі права захищені.' : 'All Rights Reserved.'}</span>
					</div>
				</div>
			</div>
			<Sitemap sitemapOpened={sitemapOpened} setSitemapOpened={setSitemapOpened} />
		</div>
	);
}
