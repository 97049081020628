import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';

import Modal from '../../common/Modal/Modal';

import { ReactComponent as Vector } from '../../Icons/vector.svg';
import { ReactComponent as Logo } from '../../Icons/logoTransparent.svg';
import { ReactComponent as Close } from '../../Icons/close.svg';

import './Sitemap.scss';
import { HashLink } from 'react-router-hash-link';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import Media from 'react-media';

interface SitemapProps {
	sitemapOpened: boolean;
	setSitemapOpened: Dispatch<SetStateAction<boolean>>;
}

export default function Sitemap({ sitemapOpened, setSitemapOpened }: SitemapProps) {
	const [language] = useSessionStorage('language', '');
	return (
		<Modal isOpen={sitemapOpened} onRequestClose={() => setSitemapOpened(false)} className="sitemapModalOverlay">
			<div className="sitemapModalContent">
				<div onClick={() => setSitemapOpened(false)} className="sitemapCloseWrapper">
					<Close />
				</div>
				<div className="sitemapLinks">
					<div className="sitemapTitle">Sitemap</div>
					<Link onClick={() => setSitemapOpened(false)} to="/" className="sitemapLink">
						{language !== 'ua' ? 'Домашня сторінка' : 'Home'}
					</Link>
					<Link onClick={() => setSitemapOpened(false)} to="service/recruitment" className="sitemapLink">
						{language === 'ua' ? 'Наші послуги' : 'Services'}
					</Link>
					<Media query="(max-width: 768px)">
						{(matches) => (
							<div className="siteMapSubLinks">
								<Link onClick={() => setSitemapOpened(false)} to="service/recruitment" className="siteMapSubLink">
									{language === 'ua' ? 'Рекрутинг' : 'Recruitment'}
								</Link>
								{!matches && <span>&nbsp;-&nbsp;</span>}
								<Link onClick={() => setSitemapOpened(false)} to="service/project-coordination" className="siteMapSubLink">
									{language === 'ua' ? 'Координація проектів' : 'Project Coordination'}
								</Link>
								{!matches && <span>&nbsp;-&nbsp;</span>}
								<Link onClick={() => setSitemapOpened(false)} to="service/support" className="siteMapSubLink">
									{language === 'ua' ? 'Підтримка' : 'Support'}
								</Link>
								{!matches && <span>&nbsp;-&nbsp;</span>}
								<Link onClick={() => setSitemapOpened(false)} to="service/human-resources" className="siteMapSubLink">
									{language === 'ua' ? 'Human Resources' : 'Human Resources'}
								</Link>
								{!matches && <span>&nbsp;-&nbsp;</span>}
								<Link onClick={() => setSitemapOpened(false)} to="service/sales" className="siteMapSubLink">
									{language === 'ua' ? 'Маркетинг та продажі' : 'Sales and Marketing'}
								</Link>
								{!matches && <span>&nbsp;-&nbsp;</span>}
								<Link onClick={() => setSitemapOpened(false)} to="service/ict-management" className="siteMapSubLink">
									{language === 'ua' ? 'Управління інфраструктурою' : 'ICT Management'}
								</Link>
							</div>
						)}
					</Media>
					<Link onClick={() => setSitemapOpened(false)} to="/vacancies" className="sitemapLink">
						{language === 'ua' ? 'Відкриті вакансії' : 'Job Openings'}
					</Link>
					{language !== 'ua' && (
						<Link onClick={() => setSitemapOpened(false)} to="/joinus" className="sitemapLink">
							<HashLink to="/joinus#joinUsPlanner" className="sitemapLink">
								Join Us
							</HashLink>
						</Link>
					)}
					<Link onClick={() => setSitemapOpened(false)} to="/about" className="sitemapLink">
						{language === 'ua' ? 'Про нас' : 'About Us'}
					</Link>
					{language !== 'ua' && (
						<>
							<Link onClick={() => setSitemapOpened(false)} to="/team" className="sitemapLink">
								The Team
							</Link>

							{/* <Link onClick={() => setSitemapOpened(false)} to="/blog" className="sitemapLink">
								Blog
							</Link> */}
						</>
					)}
					<Link onClick={() => setSitemapOpened(false)} to="/contact" className="sitemapLink">
						{language === 'ua' ? 'Контакти' : 'Contact'}
					</Link>
					<Link onClick={() => setSitemapOpened(false)} to="/privacy" className="sitemapLink">
						{language === 'ua' ? 'Політика Конфіденційності' : 'Privacy'}
					</Link>
					<Link onClick={() => setSitemapOpened(false)} to="/terms" className="sitemapLink">
						{language === 'ua' ? 'Правила та умови' : 'Terms'}
					</Link>
				</div>
				<div className="sitemapBackgraundWrapper">
					<Vector className="sitemapModalVectorBigger" />
					<Vector className="sitemapModalVector" />
				</div>
				<div className="sitemapLogoWrapper">
					<Logo />
				</div>
			</div>
		</Modal>
	);
}
