import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase/app';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FirestoreProvider } from '@react-firebase/firestore';
import { config } from './firebase/firebaseConfigs';
import getPlainRoutes from './routes';

import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Cookies from './Components/common/Cookies/Cookies';
import useLocalStorage from './Components/common/LocalStorage';
import ContactUsModal from './Components/ContactUsModal/ContactUsModal';
import ScrollToTop from './Components/common/ScrollToTop/ScrollToTop';

import { ReactComponent as ContactUs } from './Components/Icons/contactUs.svg';
import { ReactComponent as Close } from './Components/Icons/close.svg';

import Subscribe from './Components/Subscribe/Subscribe';
import { useSessionStorage } from './hooks/useSessionStorage';

import './App.scss';

export default function App() {
	const [cookiesAccepted, setCookiesAccepted] = useLocalStorage('cookies', '');
	const [cookiesClosed, setCookiesClosed] = useState(false);
	const [covidOpened, setCovidOpened] = useState(false);
	const [contactModal, setContactModal] = useState(false);
	const [extraStaffButton, setExtraStaffButton] = useState(false);
	const [closedExtraStaff, setClosedExtraStaff] = useState(false);
	const [sitemapOpened, setSitemapOpened] = useState(false);
	const [countryCode, setCountryCode] = useState('ua');

	useEffect(() => {
		fetch('https://ipapi.co/json/')
			.then((res) => res.json())
			.then((response) => {
				setCountryCode(response.country_code.toLowerCase() !== 'ua' ? 'en' : response.country_code.toLowerCase());
			})
			.catch((err) => {
				console.log('Request failed:', err);
			});
	}, []);

	const [language, setLanguage] = useSessionStorage('language', countryCode);

	setTimeout(() => setExtraStaffButton(true), 30000);
	clearTimeout(setTimeout(() => setExtraStaffButton(true), 30000));

	return (
		<FirestoreProvider firebase={firebase} {...config}>
			<HelmetProvider>
				<Router>
					<ScrollToTop>
						<Helmet>
							<title>GISUA | Global IT Support Ukraine</title>
							<meta
								name="description"
								content={
									'Markets are changing. New demands. New realities. Remote work is the world’s new standard. Global IT Support Ukraine has extensive experience with remote workers. We provide experts in all major IT fields in addition to your workforce and integrate them seamlessly, all working remotely in Ukraine.'
								}
							/>
							<meta property="og:title" content="Global IT Support Ukraine" />
							<meta property="og:description" content={'Best IT outstaffing in Ukraine'} />
							<meta property="og:image" content="https://gisua.com/logo512.png" />
						</Helmet>
						<Switch>
							{getPlainRoutes([
								'home',
								'about',
								'team',
								'contact',
								'privacy',
								'terms',
								'joinus',
								'services',
								'service',
								'vacancies',
								'vacancy',
								'techTalent',
								'myteam',
								'softwareteam',
								'supportteam',
								'wiki',
								'wikiArticle',
								'questionnaire',
								'questionnaireBali',
								'contactUsForm',
							]).map(({ path, exact }) => (
								<Route path={path} exact={exact} key={path}>
									<Header
										setSitemapOpened={setSitemapOpened}
										setCovidOpened={setCovidOpened}
										language={language}
										setLanguage={setLanguage}
										covidOpened={covidOpened}
									/>
								</Route>
							))}
						</Switch>

						<div className="componentWrapper">
							<Switch>
								<Redirect from="/en" to="/" />
								<Redirect from="/ua" to="/" />
								{getPlainRoutes().map(({ path, component: Component, exact }) => (
									<Route path={path} render={(props) => <Component {...props} />} exact={exact} key={path || '/404'} />
								))}
							</Switch>
						</div>
						<Switch>
							{getPlainRoutes([
								'home',
								'about',
								'team',
								'contact',
								'terms',
								'joinus',
								'services',
								'service',
								'vacancies',
								'vacancy',
								'blog',
								'article',
								'techTalent',
								'wiki',
								'wikiArticle',
							]).map(({ path, exact }) => (
								<Route path={path} exact={exact} key={path}>
									<Subscribe language={language} />
								</Route>
							))}
						</Switch>
						<Switch>
							{getPlainRoutes([
								'home',
								'about',
								'team',
								'contact',
								'privacy',
								'terms',
								'joinus',
								'services',
								'service',
								'vacancies',
								'vacancy',
								'techTalent',
								'myteam',
								'softwareteam',
								'supportteam',
								'wiki',
								'wikiArticle',
								'questionnaire',
								'questionnaireBali',
								'contactUsForm',
							]).map(({ path, exact }) => (
								<Route path={path} exact={exact} key={path}>
									<Footer language={language} sitemapOpened={sitemapOpened} setSitemapOpened={setSitemapOpened} />
									{!covidOpened && extraStaffButton && !closedExtraStaff && (
										<button onClick={() => setContactModal(true)} className="extraStaffContactButton">
											<span className="extraStaffContactText">Send us message</span>
											<ContactUs />
											<button
												onClick={(e) => {
													e.stopPropagation();
													setClosedExtraStaff(true);
												}}
												className="closeExtraStaffContact"
											>
												<Close />
											</button>
										</button>
									)}
									{cookiesAccepted !== 'accepted' && !cookiesClosed && (
										<Cookies language={language} setCookiesAccepted={setCookiesAccepted} setCookiesClosed={setCookiesClosed} />
									)}
								</Route>
							))}
						</Switch>
						{setContactModal && <ContactUsModal contactModal={contactModal} setContactModal={setContactModal} extraStaff />}
					</ScrollToTop>
				</Router>
			</HelmetProvider>
		</FirestoreProvider>
	);
}
