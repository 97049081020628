import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FirestoreCollection } from '@react-firebase/firestore';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Media from 'react-media';

import Social from '../Social/Social';
import MenuModal from './MenuModal/MenuModal';
import Covid from '../common/Covid/Covid';

import { ReactComponent as Logo } from '../Icons/logo.svg';
import { ReactComponent as LogoTransparent } from '../Icons/logoTransparent.svg';
import { ReactComponent as Right } from '../Icons/right.svg';
import { ReactComponent as Menu } from '../Icons/menu.svg';
import { ReactComponent as MenuBlack } from '../Icons/menuBlack.svg';
import { ReactComponent as Shape } from '../Icons/shape.svg';

import './Header.scss';

interface HeaderProps {
	setSitemapOpened: Dispatch<SetStateAction<boolean>>;
	setCovidOpened: Dispatch<SetStateAction<boolean>>;
	covidOpened: boolean;
	language: string;
	setLanguage: Dispatch<SetStateAction<string>>;
}

export default function Header({ setSitemapOpened, setCovidOpened, covidOpened, language, setLanguage }: HeaderProps) {
	const [menuOpened, setMenuOpened] = useState(false);
	const [headerChanged, setHeaderChanged] = useState(false);
	const [expertiseDropdown, setExpertiseDropdown] = useState(false);
	const [aboutDropdown, setAboutDropdown] = useState(false);
	const [langDropdownOpened, setLangDropdownOpened] = useState(false);
	const location = useLocation();

	const listenScrollEvent = () => {
		if (window.scrollY < 70) {
			return setHeaderChanged(false);
		} else if (window.scrollY > 140) {
			return setHeaderChanged(true);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', listenScrollEvent);

		return () => window.removeEventListener('scroll', listenScrollEvent);
	}, []);

	return (
		<>
			<div className={location.pathname === '/' ? 'homeHeader' : 'header'}>
				<div className={headerChanged ? 'stickyHeaderContent' : 'headerContent'}>
					<NavLink to="/" className="logoWrapper">
						{location.pathname === '/' && !headerChanged ? (
							<LogoTransparent className="logoIcon" />
						) : (
							<Logo className="logoIcon" />
						)}
					</NavLink>
					<Media query="(max-width: 930px)">
						{(matches) =>
							!matches ? (
								<>
									<div className="headerMenuWrapper">
										<div
											className="headerLinkWrapper"
											onMouseEnter={() => setAboutDropdown(true)}
											onMouseLeave={() => setAboutDropdown(false)}
										>
											<NavLink
												to="/about"
												className={aboutDropdown ? 'headerLinkOpened' : 'headerLink'}
												activeClassName="activeHeaderLink"
											>
												{language === 'ua' ? 'Про нас' : 'About'} {language !== 'ua' && <Right />}
											</NavLink>
											{aboutDropdown && language !== 'ua' && (
												<div
													className={
														location.pathname === '/' && !headerChanged ? 'stickyHeaderLinkDropdown' : 'headerLinkDropdown'
													}
												>
													<div className="headerDropdownMenu">
														<HashLink to="/about#company" className="menuDropdownLink">
															Company
														</HashLink>
														<Link to="/team" className="menuDropdownLink">
															The team
														</Link>
														<Link to="/techTalent" className="menuDropdownLink">
															Tech Talent
														</Link>
													</div>
												</div>
											)}
										</div>
										<div className="headerDevider" />
										<div
											className="headerLinkWrapper"
											onMouseEnter={() => setExpertiseDropdown(true)}
											onMouseLeave={() => setExpertiseDropdown(false)}
										>
											<NavLink
												to="/services"
												className={expertiseDropdown ? 'headerLinkOpened' : 'headerLink'}
												activeClassName="activeHeaderLink"
											>
												{language === 'ua' ? 'Наші послуги' : 'Services'} <Right />
											</NavLink>
											{expertiseDropdown && (
												<div
													className={
														location.pathname === '/' && !headerChanged
															? 'stickyHeaderLinkDropdownService'
															: 'headerLinkDropdownService'
													}
												>
													<div className="headerDropdownMenu">
														<Link to="/service/recruitment" className="menuDropdownLink">
															{language === 'ua' ? 'Рекрутинг' : 'Recruitment'}
														</Link>
														<Link to="/service/project-coordination" className="menuDropdownLink">
															{language === 'ua' ? 'Координація проектів' : 'Project Coordination'}
														</Link>
														<Link to="/service/support" className="menuDropdownLink">
															{language === 'ua' ? 'Підтримка' : 'Support'}
														</Link>
														<Link to="/service/human-resources" className="menuDropdownLink">
															{language === 'ua' ? 'Human Resources' : 'Human Resources'}
														</Link>
														<Link to="/service/sales" className="menuDropdownLink">
															{language === 'ua' ? 'Маркетинг та продажі' : 'Sales and Marketing'}
														</Link>
														<Link to="/service/ict-management" className="menuDropdownLink">
															{language === 'ua' ? 'Управління інфраструктурою' : 'ICT Management'}
														</Link>
													</div>
												</div>
											)}
										</div>
										<div className="headerDevider" />
										<div className="headerLinkWrapper">
											<NavLink to="/contact" className="headerLink" activeClassName="activeHeaderLink">
												<HashLink to="/contact#contactPlanner" className="headerLink">
													{language === 'ua' ? 'Контакти' : 'Contact'}
												</HashLink>
											</NavLink>
										</div>
										{language !== 'ua' && (
											<>
												<div className="headerDevider" />
												<div className="headerLinkWrapper">
													<NavLink to="/myteam" className="headerLink" activeClassName="activeHeaderLink">
														Build a team
													</NavLink>
												</div>

												{/* <div className="headerDevider" />
												<div className="headerLinkWrapper">
													<NavLink to="/blog" className="headerLink" activeClassName="activeHeaderLink">
														Blog
													</NavLink>
												</div> */}
											</>
										)}
										<div className="headerDevider" />
										<div className="headerLinkWrapper">
											<NavLink to="/vacancies" className="headerLink" activeClassName="activeHeaderLink">
												{language === 'ua' ? 'Відкриті вакансії' : 'Job Opening'}
											</NavLink>
										</div>
										{language !== 'ua' && (
											<>
												<div className="headerDevider" />
												<div className="headerLinkWrapper">
													<NavLink to="/joinus" className="headerLink" activeClassName="activeHeaderLink">
														<HashLink to="/joinus#joinUsPlanner" className="headerLink">
															Join Us
														</HashLink>
													</NavLink>
												</div>
											</>
										)}
									</div>
									<div className="infoWithSocialWrapper">
										<div
											className={location.pathname === '/' && !headerChanged ? 'languageContainer' : 'languageContainerSticky'}
										>
											<div
												className={langDropdownOpened ? 'languageSelectorOpened' : 'languageSelector'}
												onClick={() => setLangDropdownOpened(!langDropdownOpened)}
											>
												{language !== 'ua' ? 'EN' : 'UA'}
												<Shape />
											</div>
											{langDropdownOpened && (
												<div className="languageDropdown">
													<div
														className="languageDropdownValue"
														onClick={() => {
															setLanguage(language === 'en' ? 'ua' : 'en');
															setLangDropdownOpened(false);
															window.location.reload();
														}}
													>
														{language === 'en' ? 'UA' : 'EN'}
													</div>
												</div>
											)}
										</div>
										<FirestoreCollection path="/infoModal/">
											{(infoData) => {
												if (infoData.isLoading) {
													return;
												}
												return infoData.value[0].shown && infoData.value[0].image.original.src ? (
													<div className="headerCovidWrapper">
														<button onClick={() => setCovidOpened(true)} className="headerCovidButton">
															<img src={infoData.value[0].image.original.src} alt="" />
														</button>
														{covidOpened && <Covid setCovidOpened={setCovidOpened} {...infoData.value[0]} />}
													</div>
												) : null;
											}}
										</FirestoreCollection>
										<div className="socialIconsWrapper">
											<Social />
										</div>
									</div>
								</>
							) : (
								<div className="menuHeaderWrapper">
									<FirestoreCollection path="/infoModal/">
										{(infoData) => {
											if (infoData.isLoading) {
												return;
											}
											return infoData.value[0].shown && infoData.value[0].image.original.src ? (
												<div className="headerCovidWrapper">
													<div onClick={() => setCovidOpened(true)} className="headerCovidButton">
														<img src={infoData.value[0].image.original.src} alt="" />
													</div>
													{covidOpened && <Covid setCovidOpened={setCovidOpened} {...infoData.value[0]} />}
												</div>
											) : null;
										}}
									</FirestoreCollection>
									<div onClick={() => setMenuOpened(true)} className="menuHeader">
										{location.pathname === '/' && !headerChanged ? <Menu /> : <MenuBlack />}
									</div>
									<MenuModal
										menuOpened={menuOpened}
										setMenuOpened={setMenuOpened}
										setCovidOpened={setCovidOpened}
										covidOpened={covidOpened}
										language={language}
										setLanguage={setLanguage}
										langDropdownOpened={langDropdownOpened}
										setLangDropdownOpened={setLangDropdownOpened}
										setSitemapOpened={setSitemapOpened}
									/>
								</div>
							)
						}
					</Media>
				</div>
			</div>
		</>
	);
}
